<template>
  <router-view/>
</template>

<style lang="scss">
body, html {
  background: #696969;
}
* {
  padding: 0;margin: 0;
}
</style>
