import axios from "axios";
import global from "../../public/global";
import { ElLoading } from 'element-plus'
import qs from 'qs'
import index from "./index";
import router from "../router";
// 基础配置
axios.create({
    timeout: 20 * 1000,
    headers:{'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'} //配置请求头
})


let doPost = function (url, paramsData = {}, config) {
    return new Promise((resolve, reject) =>{
        let theUrl = global.baseUrl + url;
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        let token = index.state.token
        if(token != '')
        {
            paramsData['token'] = token
        }
        axios.post(theUrl, qs.stringify(paramsData), config).then(res =>{
            loading.close()
            if(res.data.code == 20004)
            {
                router.push('/login')
                return
            }
            resolve(res.data);
        }, err =>{
            loading.close()
            reject(err.data);
        })
    })
}

let doGet = function (url, paramsData = {}, config) {
    return new Promise((resolve, reject) => {
        let theUrl = global.baseUrl + url;
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        axios.get(theUrl, {params: paramsData, ...config}).then(
            res =>{
                loading.close()
                resolve(res.data);
            }).catch(err =>{
                loading.close()
                reject(err.data);
        })
    })
}

export default {
    doPost,
    doGet
};