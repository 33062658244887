import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
    children: [
      {path: '/aa', name: 'aa', component: () => import('@/views/home/rightnav/aa')},
      {path: '/wei1', name: 'wei1', component: () => import('@/views/home/rightnav/wei1')},
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
